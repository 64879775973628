import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button disabled variant="contained" color="primary" onClick={handleClickOpen('paper')}>Ghid drpciv.ro pentru reozlvare chestionare si planificare online la examenul teoretic</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Ghid autentificare DRPCIV</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <h3>Accesati din browser site-ul drpciv.ro</h3>
            <img id="imagini_ghid" src="imagini_galerie/d1.png" />
            <h3>Apasati pe butonul "Autentificare" din dreapta paginii, ca in imaginea de mai jos:</h3>
            <img id="imagini_ghid" src="imagini_galerie/d2.png" />
            <h3>Apasati pe butonul "Creeaza cont", ca in imaginea de mai jos:</h3>
            <img id="imagini_ghid" src="imagini_galerie/d3.png" />
            <h3>Completati datele cerute si apasati accepta, dupa care intrati pe mail pentru a confirma contul</h3>
            <img id="imagini_ghid" src="imagini_galerie/d4.png" />
            <h3>Dupa ce ati confirmat contul de pe adresa de email, apasati "Autentificare" ca in primul pas, 
            introduceti datele contului si derulati pagina in jos pana ajungeti la butonul "Testare examen online",
             ca in imaginea de mai jos</h3>
            <img id="imagini_ghid" src="imagini_galerie/d5.png" />
            <h3>Mult succes!</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Am inteles
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}