import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import ContactSupportRoundedIcon from '@material-ui/icons/ContactSupportRounded';
import PhotoLibraryRoundedIcon from '@material-ui/icons/PhotoLibraryRounded';
import ContactPhoneRoundedIcon from '@material-ui/icons/ContactPhoneRounded';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { ThemeProvider } from '@material-ui/core/styles';
import Gallery from 'react-grid-gallery';
import CustomizedTimeline from './timeline.js'
import IMAGES from './images.js'
import BackgroundVideo from './bgvideo.js'
import Chips from './chips_categorii.js'
import theme from './theme.js'
import { makeStyles } from '@material-ui/core/styles';
import BasicTimeline from './timeline_instruire.js'
import ScrollDialog from './ghid.js'
import Button from '@material-ui/core/Button';
import AlbaIuliaMap from './AlbaIuliaMap.js'
import CugirMap from './CugirMap.js'

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
}));

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

class SiglaScoalaMeniu extends React.Component {
	render() {
		return <img alt="sigla scoala" src="sigla_scoala.png" id="sigla_meniu"></img>;
	}
}

class Footer extends React.Component {
	render() {
		return <div>
							
						</div>;
	}
}

export default function ScrollableTabsButtonAuto() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<ThemeProvider theme={theme}>
		<div className={classes.root}>
			<div style = {{zIndex:"14", position:"fixed", top: "0", width: "100%"}}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>

					<Tab icon={<SiglaScoalaMeniu />} {...a11yProps(0)} />
					<Tab label="Despre noi" icon={<TimelineRoundedIcon />} {...a11yProps(1)} />
					<Tab label="Servicii" icon={<DriveEtaRoundedIcon />} {...a11yProps(2)} />
					<Tab label="Informatii generale" icon={<ContactSupportRoundedIcon />} {...a11yProps(3)} />
					<Tab label="Galerie foto" icon={<PhotoLibraryRoundedIcon />} {...a11yProps(4)} />
					<Tab label="Contact" icon={<ContactPhoneRoundedIcon />} {...a11yProps(5)} />
				</Tabs>
			</AppBar>
			</div>
			<TabPanel value={value} index={0}>
				<BackgroundVideo />
				<Footer />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<Container maxWidth="md" id="container"> 
						<br /><br />
						<CustomizedTimeline />
						<Footer />
				</Container>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Container maxWidth="md" id="container"> 
						<br /><br /><br />
						<Chips />
						<div id="body_chip"></div>
					<Footer />
				</Container>
			</TabPanel>
			<TabPanel value={value} index={3}>
				<Container maxWidth="md" id="container"> 
						<br /><br /><br />
						<div style={{width: "100%", textAlign:"center"}}>
							<ScrollDialog />
						</div>
						<h3>Parcursul instruirii la Scoala de soferi Tudor: </h3>
						<div style={{width: "70%", left:"0", textAlign:"left"}}>
						<BasicTimeline />
						</div>
						<h3>Pregatirea teoretica pentru categoriile A, A1, A2, AM, B, C, CE, D:</h3>
						<ul>
							<li>profesori de legislatie rutiera cu experienta de lunga durata</li>
							<li>cursuri de legislatie, mecanica, conducere preventiva si prim-ajutor</li>
							<li>pregatire pe calculator, prin soft autorizat: atat intrebari si raspunsuri pe capitole, cat si chestionare similare celor de la examenul cu politia</li>
							<li>materiale didactice de cea mai inalta calitate(inclusiv in format electronic)</li>
							<li>sala de legislatie si cea mecanica dotate cu video-proiector, calculatoare, acces la internet</li>
								</ul>
								<h3>Pregatirea practica pentru categoriile A, A1, A2, AM, B, C, CE, D:</h3>
								<ul>
									<li>conforma cu programa de invatamant in vigoare</li>
									<li>instructori cu experienta atat in domeniul auto, cat si in cel pedagogic</li>
									<li>autovehicule de ultima generatie (imagini in galeria foto):<br />
										<ul style={{listStyleType:"none"}}>
											<li>
												<b>Categoria A</b>
												<ul style={{listStyleType:"disc"}}>
													<li>Motocicleta Honda Hornet</li>
													<li>Motocicleta Suzuki SV650</li>
												</ul>
											</li>
											<li>
												<b>Categoria A1</b>
												<ul style={{listStyleType:"disc"}}>
													<li>Motocicleta Suzuki GN125</li>
												</ul>
											</li>
											<li>
												<b>Categoria A2</b>
												<ul style={{listStyleType:"disc"}}>
													<li>Motocicleta Suzuki GS500</li>
												</ul>
												
											</li>
											<li>
												<b>Categoria AM</b>
												<ul style={{listStyleType:"disc"}}>
													<li>Moped FirstBike Activ</li>
												</ul>
											</li>
											<li>
												<b>Categoria B</b>
												<ul style={{listStyleType:"disc"}}>
													<li>Skoda Rapid</li>
													<li>Volkswagen Golf 6</li>
													<li>Volkswagen Golf 5</li>
													<li>Renault Megane III</li>
													<li>Opel Corsa D Hatchback</li>
													<li>Volkswagen Golf 4 Variant</li>
												</ul>
											</li>
											<li>
												<b>Categoria C si CE</b>
												<ul style={{listStyleType:"disc"}}>
													<li>MAN Silent</li>
													<li>Remorca Koegel</li>
												</ul>
											</li>
											<li>
												<b>Categoria D</b>
												<ul style={{listStyleType:"disc"}}>
													<li>Autobuz Setra</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
									<h3>Impreuna cu IFPTR, cursuri de formare profesionala in transporturi auto pentru: </h3>
						<ul>
							<li>conducatori auto(marfa, persoane, agabaritic, transport vehicule defecte sau avariate)</li>
							<li>manageri transport(marfa, persoane)</li>
							<li>transport marfuri periculoase - ADR</li>
							<li>instructori auto si profesori de legislatie rutiera</li>
							<li>atestare TAXI</li>
						</ul>
						<Footer />
				</Container>
			</TabPanel>
			<TabPanel value={value} index={4}>
				<Container maxWidth="md" id="container"> 
						<br /><br />
						<Gallery images={IMAGES}/>
				</Container>
			</TabPanel>
			<TabPanel value={value} index={5}>
				<Container maxWidth="md" id="container">
					<br /><br /><br />
					
					<div style={{textAlign: "center", backgroundColor: "#eeeeee", verticalAlign:"top"}} className={classes.root}> 

						<div style={{width: "100%", textAlign:"center"}}>
							<Button href="#mesaj_sc_tudor" variant="contained" color="primary">Trimite mesaj catre Scoala Tudor</Button>
						</div>
						<h2>Scoala Tudor are sediul in Alba Iulia</h2>
						<br />
						<table style={{textAlign: "center", width: "100%", paddingLeft:"25%", paddingRight:"25%"}}>
							<tr> 
								<th style={{width:"50%"}}><hr></hr>Alba Iulia</th>
							</tr>
							<tr>
								<td><img src="ai.jpg" style={{width:"100%"}}></img></td>
							</tr>
							<tr>
								<td>str. Decebal nr. 27,<br></br>langa redactia Ziarului Unirea</td>
							</tr>
							<tr>
								<td>Tel: 0744 602 189<br></br>Fax: 0258 810 832</td>
							</tr>
							<tr>
								<td>
									<AlbaIuliaMap />
								</td>
							</tr>
						</table>
						<br /><br />
						<a name="mesaj_sc_tudor"></a>
						<form style={{textAlign: "center"}} action="mailer.php" method="POST" enctype="multipart/form-data">
							<br></br>
							<table style={{width: "100%"}}>
								<tr>
								<td style={{textAlign: "center"}} colspan="2"><b><h3>Trimite un mesaj catre office@scoalatudor.ro</h3></b> <hr></hr></td>
								</tr>
								<tr>
									<td style={{textAlign: "right"}}>Nume si prenume</td>
									<th style={{textAlign: "left"}}><input name="nume" type="text" /></th>
								</tr>
								<tr>
									<td style={{textAlign: "right"}}>Adresa dvs de mail</td>
									<th style={{textAlign: "left"}}><input name="mail" type="email" /></th>
								</tr>
								<tr>
									<td style={{textAlign: "right", verticalAlign:"top"}}>Mesaj</td>
									<th style={{textAlign: "left"}}><textarea style={{width:"100%", height: "200px"}} name="mesaj"></textarea></th>
								</tr>
								<tr style={{textAlign: "center"}}>
									<td colspan="2"><input name="submit" type="submit" value="Trimite mesaj" /></td>
								</tr>
							</table>
						</form>
						<Footer />
					</div>
				</Container>
			</TabPanel>
		</div>
		</ThemeProvider>
	);
}

const meniu = <ScrollableTabsButtonAuto/>;
ReactDOM.render(meniu, document.getElementById('menu'));

