import React from 'react';
import ReactDOM from 'react-dom';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import theme from './theme.js'
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Chips() {
  const classes = useStyles();

  const clickAM = () => {
    const moped = 
    <div>
      <h2>Categoria AM - Moped</h2>
      <ul>
        <li> Varsta minima: <b>16 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/22.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(moped, document.getElementById('body_chip'));
  };

  const clickA1 = () => {
    const a1 = 
    <div>
      <h2>Categoria A1 - Moto</h2>
      <ul>
        <li> Varsta minima: <b>16 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/23.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(a1, document.getElementById('body_chip'));
  };

  const clickA2 = () => {
    const a2 = 
    <div>
      <h2>Categoria A2 - Moto</h2>
      <ul>
        <li> Varsta minima: <b>18 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/14.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(a2, document.getElementById('body_chip'));
  };

  const clickA = () => {
    const a = 
    <div>
      <h2>Categoria A - Moto</h2>
      <ul>
        <li> Varsta minima: <b>24 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="honda2.jpeg" id="imagini_categorii" />
      </div>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/24.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(a, document.getElementById('body_chip'));
  };

  const clickB = () => {
    const b = 
    <div>
      <h2>Categoria B - Autoturism</h2>
      <ul>
        <li> Varsta minima: <b>18 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/9.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(b, document.getElementById('body_chip'));
  };

  const clickC = () => {
    const c = 
    <div>
      <h2>Categoria C - Camion</h2>
      <ul>
        <li> Varsta minima: <b>18 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/16.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(c, document.getElementById('body_chip'));
  };

  const clickCE = () => {
    const ce = 
    <div>
      <h2>Categoria CE - Camion cu remorca</h2>
      <ul>
        <li> Varsta minima: <b>18 ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/17.jpg" id="imagini_categorii" />
      <br />
      </div>
    </div>;
    ReactDOM.render(ce, document.getElementById('body_chip'));
  };

  const clickD = () => {
    const d = 
    <div>
      <h2>Categoria D - Autobuz</h2>
      <ul>
        <li> Varsta minima: <b>21 de ani</b></li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/28.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(d, document.getElementById('body_chip'));
  };

  const clickTAXI = () => {
    const taxi = 
    <div>
      <h2>Atestate Taxi si Inchiriere</h2>
      <ul>
        <li> Varsta minima: <b>21 de ani</b></li>
        <li> <b>Manageri de transport</b> taxi si inchiriere</li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/taxi.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(taxi, document.getElementById('body_chip'));
  };

  const clickT = () => {
    const t = 
    <div>
      <h2>Atestate transporturi</h2>
      <h3>Personal pregatire in domeniul auto</h3>
      <ul>
        <li> Instructori auto</li>
        <li> Profesori de legislatie rutiera</li>
      </ul>
      <h3>Transport marfa</h3>
      <ul>
        <li> Varsta minima: <b>18 de ani</b></li>
        <li> Soferi marfa, ADR si transport agabaritic</li>
        <li> Manageri de transport marfa</li>
      </ul>
      <h3>Transport persoane</h3>
      <ul>
        <li> Varsta minima: <b>21 de ani</b></li>
        <li> Soferi persoane</li>
        <li> Manageri de transport persoane</li>
        <li> Consilieri de siguranta ADR</li>
      </ul>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/16.jpg" id="imagini_categorii" />
      </div>
      <div style={{textAlign:"center", width:"100%"}}>
        <img src="imagini_galerie/28.jpg" id="imagini_categorii" />
      </div>
    </div>;
    ReactDOM.render(t, document.getElementById('body_chip'));
  };

  return (
    <ThemeProvider theme={theme}>
    <h2 style={{textAlign:"center"}}>Categorii disponibile la Scoala Tudor: AM, A1, A2, A, B, C, CE, D</h2>
    <h2 style={{textAlign:"center"}}>Categorii pentru obtinere permis</h2>
    <div style={{textAlign: "center", backgroundColor: "#eeeeee"}} className={classes.root}>
      <Chip
        avatar={<Avatar>AM</Avatar>}
        label="Moped"
        clickable
        id="chip"
        color="primary"
        onDelete={clickAM}
        onClick={clickAM}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>A1</Avatar>}
        label="Motocicleta 16 ani"
        clickable
        id="chip"
        color="primary"
        onDelete={clickA1}
        onClick={clickA1}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>A2</Avatar>}
        label="Motocicleta 18 ani"
        clickable
        id="chip"
        color="primary"
        onDelete={clickA2}
        onClick={clickA2}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>A</Avatar>}
        label="Motocicleta 24 ani"
        clickable
        id="chip"
        color="primary"
        onDelete={clickA}
        onClick={clickA}
        deleteIcon={<DoneIcon />}
      />
      <br />
      <Chip
        avatar={<Avatar>B</Avatar>}
        label="Autoturism"
        clickable
        id="chip"
        color="primary"
        onDelete={clickB}
        onClick={clickB}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>C</Avatar>}
        label="Camion"
        clickable
        id="chip"
        color="primary"
        onDelete={clickC}
        onClick={clickC}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>CE</Avatar>}
        label="Camion cu remorca"
        clickable
        id="chip"
        color="primary"
        onDelete={clickCE}
        onClick={clickCE}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>D</Avatar>}
        label="Autobuz"
        clickable
        id="chip"
        color="primary"
        onDelete={clickD}
        onClick={clickD}
        deleteIcon={<DoneIcon />}
      />
    </div>
    <h2 style={{textAlign:"center"}}>Servicii pentru profesionisti (IFPTR Alba Iulia)</h2>
    <div style={{textAlign: "center", backgroundColor: "#eeeeee"}} className={classes.root}>
      <Chip
        avatar={<Avatar>TAXI</Avatar>}
        label="Atestate TAXI si Inchiriere"
        clickable
        id="chip"
        color="primary"
        onDelete={clickTAXI}
        onClick={clickTAXI}
        deleteIcon={<DoneIcon />}
      />
      <Chip
        avatar={<Avatar>T</Avatar>}
        label="Atestate transporturi"
        clickable
        id="chip"
        color="primary"
        onDelete={clickT}
        onClick={clickT}
        deleteIcon={<DoneIcon />}
      />
      <h5>click pe categorie pentru detalii</h5>
      <br /> 
    </div>
    </ThemeProvider>
  );
}