// PHOTO GALLERY
const IMAGES =
[{
        src: "imagini_galerie/1.jpg",
        thumbnail: "imagini_galerie/1-thumbnail.jpg",
        thumbnailWidth: 350,
        isSelected: false,
        tags: [{value: "Alba Iulia"}], 
        caption: "Intrare scoala de soferi Tudor Alba Iulia"
},
{
        src: "imagini_galerie/2.jpg",
        thumbnail: "imagini_galerie/2.jpg",
        thumbnailWidth: 320,
        caption: "Sala mecanica"
},

{
        src: "imagini_galerie/3.jpg",
        thumbnail: "imagini_galerie/3.jpg",
        thumbnailWidth: 320,
        caption: "Sala teorie"
},

{
        src: "imagini_galerie/4.jpg",
        thumbnail: "imagini_galerie/4.jpg",
        thumbnailWidth: 320,
        caption: "Secretariat"
},

{
        src: "imagini_galerie/5.jpg",
        thumbnail: "imagini_galerie/5.jpg",
        thumbnailWidth: 320,
        caption: "Sala teorie"
},

{
        src: "imagini_galerie/6.jpg",
        thumbnail: "imagini_galerie/6.jpg",
        thumbnailWidth: 320,
        caption: "Sala mecanica"
},

{
        src: "imagini_galerie/7.jpg",
        thumbnail: "imagini_galerie/7.jpg",
        thumbnailWidth: 320,
        caption: "Sala mecanica"
},

{
        src: "imagini_galerie/8.jpg",
        thumbnail: "imagini_galerie/8.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Golf VI"}], 
},

{
        src: "imagini_galerie/9.jpg",
        thumbnail: "imagini_galerie/9.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/10.jpg",
        thumbnail: "imagini_galerie/10.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Golf V"}], 
},

{
        src: "imagini_galerie/11.jpg",
        thumbnail: "imagini_galerie/11.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/12.jpg",
        thumbnail: "imagini_galerie/12.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Opel Corsa"}], 
},

{
        src: "imagini_galerie/13.jpg",
        thumbnail: "imagini_galerie/13.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/14.jpg",
        thumbnail: "imagini_galerie/14.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Moto A2"}], 
},

{
        src: "imagini_galerie/15.jpg",
        thumbnail: "imagini_galerie/15.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Skoda Rapid"}], 
},

{
        src: "imagini_galerie/20.jpg",
        thumbnail: "imagini_galerie/20.jpg",
        thumbnailWidth: 320
},
{
        src: "imagini_galerie/16.jpg",
        thumbnail: "imagini_galerie/16.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Camion MAN"}], 
},

{
        src: "imagini_galerie/17.jpg",
        thumbnail: "imagini_galerie/17.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/18.jpg",
        thumbnail: "imagini_galerie/18.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Golf IV"}], 
},

{
        src: "imagini_galerie/19.jpg",
        thumbnail: "imagini_galerie/19.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/22.jpg",
        thumbnail: "imagini_galerie/22.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Moped"}], 
},

{
        src: "imagini_galerie/23.jpg",
        thumbnail: "imagini_galerie/23.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Moto A1"}], 
},

{
        src: "imagini_galerie/24.jpg",
        thumbnail: "imagini_galerie/24.jpg",
        thumbnailWidth: 320,
        tags: [{value: "Moto A"}], 
},

{
        src: "imagini_galerie/25.jpg",
        thumbnail: "imagini_galerie/25.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/26.jpg",
        thumbnail: "imagini_galerie/26.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/27.jpg",
        thumbnail: "imagini_galerie/27.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/28.jpg",
        thumbnail: "imagini_galerie/28.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/29.jpg",
        thumbnail: "imagini_galerie/29.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/30.jpg",
        thumbnail: "imagini_galerie/30.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/31.jpg",
        thumbnail: "imagini_galerie/31.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/32.jpg",
        thumbnail: "imagini_galerie/32.jpg",
        thumbnailWidth: 320,
},

{
        src: "imagini_galerie/33.jpg",
        thumbnail: "imagini_galerie/33.jpg",
        thumbnailWidth: 320,
}]

export default IMAGES;