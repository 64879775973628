import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

const AnyReactComponent = ({ text }) => <div><DriveEtaIcon /></div>;

class CugirMap extends Component {
  static defaultProps = {
    center: {
      lat: 45.840450,
      lng: 23.364567
    },
    zoom: 14
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '250px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDGDavK1BxFHJBEalBFGGZvknhhmY1_xsQ" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={45.840450}
            lng={23.364567}
            text="Scoala Tudor"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default CugirMap;