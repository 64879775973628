// BACKGROUND VIDEO
import React from 'react';
import classes from './BackgroundVideo.module.css';

const BackgroundVideo = () => {
    const videoSource = "videoSST.mp4"
    return (
        <div className={classes.Container} >
            <video autoFill autoPlay="autoplay" loop="loop" muted className={classes.Video} >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className={classes.Content}>
                <div className={classes.SubContent} >
                    <h1>Scoala de soferi TUDOR</h1>
                    <h2><i>Think before you drive</i></h2>
                    <h3 style={{color: "#dedede"}}>Invata sa circuli, nu doar sa iti iei examenul!</h3>
                    <h3 style={{color: "#dedede"}}>Oferim servicii de pregatire auto pentru amatori si profesionisti in Alba Iulia</h3>
                    <h3 style={{color: "#dedede"}}>La prima scoala de soferi particulara din judetul Alba, infiintata in 1992</h3>
                    <h2>Calitate  -  Profesionalism  -  Seriozitate</h2>
                    </div>
            </div>
        </div>
    )
}//<button type="button" className="btn btn-outline-dark">Ce oferim noi</button>

export default BackgroundVideo;