import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default function BasicTimeline() {
  return (
    <Timeline>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Veniti la Scoala Tudor</b>
          <br />
          Ne cunoastem si facem preinscrierea pe baza cartii de identitate si daca e cazul, a permisului de conducere existent
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Acte necesare pentru inscriere</b>
          <br />
          Veti primi de la noi fisa medicala si fisa de scolarizare, necesare la efectuarea <b>vizitei medicale</b> la o clinica din oras (in prealabil trebuie sa obtineti de la medicul de familie o adeverinta referitoare la antecedentele medicale), trebuie sa obtineti un certificat de <b>cazier judiciar</b> de la politie si un <b>aviz psihologic</b> (probabil, tot de la policlinica unde veti efectua vizita medicala)
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Inscrierea propriu zisa</b>
          <br />
          Pe baza actelor de mai sus, se realizeaza inscrierea efectiva in prima serie de pregatire ce va urma sa inceapa. Cu aceasta ocazie se va intocmi si semna contractul de scolarizare aferent pregatirii
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Materiale didactice</b>
          <br />
          Primiti (contra cost) o carte de legislatie cu aplicatie si cod unic, astfel incat sa puteti sa exersati si acasa. De asemenea se poate achizitiona un DVD ce contine o aplicatie cu toate resursele didactice necesare pregatirii la orice categorie solicitata (cursuri teoretice, intrebari pe capitole, teste pentru toate categoriile, explicate, filme de prezentare a exercitiilor practice, etc.)
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Pregatire teoretica</b>
          <br />
          Durata: minim <b>24 de ore</b> (in functie de categorie) de-a lungul a 2 saptamani
          <br />
          Cursuri de teorie complete, desfasurate in maniera profesionista.
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Pregatire practica</b>
          <br />
          Durata: <b>in functie de categorie</b>, minim 3 saptamani
          <br />
          In functie de categoria solicitata, se efectueaza toate orele de pregatire practica prevazute in planul de invatamant, dupa un program elastic, stabilit in prealabil de catre cursant impreuna cu instructorul auto
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Antrenament la sediul scolii</b>
          <br />
          De-a lungul scolarizarii, aveti posibilitatea sa va pregatiti pentru examenul teoretic in conditii similare cu cele de la examen, pe calculatoarele existente in salile de curs, in orice interval orar dorit, pe perioada cat este deschisa scoala
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Finalizarea pregatirii</b>
          <br />
          Dupa efectuarea ultimei sedinte de pregatire practica si promovarea in prealabil a preexaminarii teoretice pe calculator, se va finaliza scolarizarea, completandu-se certificatul de absolvire de pe fisa de scolarizare si inmanandu-se fiecarui cursant dosarul aferent, ce trebuie prezantat cu ocazia examenelor teoretic si practic cu politia. <br></br>In vederea prezentarii la examenul teoretic cu Politia, este necesara pentru fiecare cursant, o programare online, pe site-ul drpciv.ro, pe care o puteti realiza singur sau asistat de catre personalul scolii
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Examen teoretic</b>
          <br />
          Examenul teoretic se va desfasura la data programarii realizate, in locatia destinata din cladirea Prefecturii Alba. La prezentarea la examen, va trebui sa aveti la dosar dovada (fizica-pe hartie) a achitarii taxei de eliberare a permisului de conducere, in valoare de 89 lei. <br></br>Aplicatia de examen va pune la dispozitie, dupa promovarea testului teoretic, posibilitatea programarii la examenul practic.
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <b>Examen practic</b>
          <br />
          Asa cum este prevazut in contractul de scolarizare, veti fi asistat la examenul practic, in mod gratuit, de catre instructorul auto cu autovehiculul cu care ati efectuat pregatirea practica.
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" />
        </TimelineSeparator>
        <TimelineContent>
          <b>Felicitari pentru obtinerea permisului si drumuri bune!</b>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}